<template>
	<div>

	</div>
</template>
<script>
	import Cookie from '@/scripts/cookie.js'
	import utils from '@/scripts/utils.js'
	import config from '@/scripts/config.js'
	export default {
		data() {
			return {
			}
		},
		created: function () {
			// localStorage.IsSSOUser = "true";
            // mgr.signinRedirect();
		},
		mounted:function(){
			debugger;
			const queryString = window.location.search;
    		const urlParams = new URLSearchParams(queryString);
			var token = urlParams.get('token');
			var domain = urlParams.get('domain');
			console.log(token);

			debugger;
			this.$AjaxRequest.AjaxSubmit("omsapi/auth/ssologin", "get", {token:token}, data => 
			{
				debugger;
				if(data.IsSuccess){
					localStorage.wmstoken = token;
					localStorage.setItem('APPID',data.Result.appId);
					if(data.Result.domain) {
						Cookie.set(Cookie.domain, data.Result.domain);
					} else {
						Cookie.set(Cookie.domain, "");
					}
					var homePath = `/${config.subdomain}/home`;
					if (!!data.Result.domain) {
						window.location.href =  `/${config.subdomain}/${domain}/home`;
					}else{
						this.$router.push({path: homePath});
					}
				} else {
					//This.errorInfo = data.OperationDesc;
				}
			}, null, true);

			//var domain = window.localSelfObj.domain;
			// localStorage.wmstoken = token;
			// Cookie.set(Cookie.domain, domain);

			// localStorage.setItem('APPID','1750101662516232194');
			// var homePath = `/${config.subdomain}/home`;
			// if (!!domain) {
			// 	window.location.href =  `/${config.subdomain}/${domain}/home`;
			// }else{
			// 	this.$router.push({
			// 		path: homePath
			// 	});
			// }
		},
		methods: {

		}
	}
</script>
